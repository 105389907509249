import Splide from '@splidejs/splide'
import Alpine from 'alpinejs'

export const TextImgSlider = () => {
    Alpine.data('initTextImgSlider', (numOfSlides: number) => ({
        init() {
            if (numOfSlides > 1) {
                new Splide( this.$el, {
                    type: 'loop',
                    perPage: 1,
                    gap: 30,
                    isNavigation: false,
                    pagination: false,
                    mediaQuery: 'min',
                    autoplay: true,
                    speed: 800,
                    easing: 'cubic-bezier(1, 0, 0, 1)',
                    breakpoints: {
                        1200: {
                            perPage: 2,
                            perMove: 1,
                            destroy: (numOfSlides > 2) ? false : true
                        },
                    }
                }).mount();
            }
        },
    }))
}