/// <reference types="vite/client" />

// Alpine
import Alpine from 'alpinejs'
import intersect from '@alpinejs/intersect'

Alpine.plugin(intersect)

// Css
import '../css/app.css'

// Splide CSS
import '@splidejs/splide/css/core'

// Components
import { initCollapsible } from './collapsible'
import { initIntersectObserver } from './intersect-observer'
import { initJSEssentials } from './js-essentials'
import { initMenuToggle } from './menu-toggle'
import { LogoSlider, NavigationSlider, TextImgSlider } from './sliders'
import { initMap } from './map'

// Init components
initCollapsible()
initIntersectObserver()
initMap()

//Includes TouchDetect + JSDetect
initJSEssentials()
initMenuToggle()

//Sliders
LogoSlider()
NavigationSlider()
TextImgSlider()

Alpine.start()

// Accept HMR as per: https://vitejs.dev/guide/api-hmr.html
if (import.meta.hot) {
    import.meta.hot.accept(() => {
        console.log('HMR')
    })
}
