import Splide from '@splidejs/splide'
import Alpine from 'alpinejs'
import { AutoScroll } from '@splidejs/splide-extension-auto-scroll'

export const LogoSlider = () => {
    Alpine.data('initLogoSlider', () => ({
        init() {
            new Splide( this.$el, {
                perPage: 7,
                type: 'loop',
                drag: 'free',
                gap: 60,
                isNavigation: false,
                arrows: false,
                pagination: false,
                fixedWidth: 255,
                fixedHeight: 30,
                autoScroll: {
                    speed: .7,
                },
            }).mount({AutoScroll});
        },
    }))
}